/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.12 public/Part_01.glb
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations, Html, Image, Box, PerspectiveCamera, OrthographicCamera, Bounds } from '@react-three/drei'
import { playAnimations, setAnimationTime } from '../3D_Components/AnimationUtilities'
import { useEffect } from 'react'
import { useFrame, useThree } from 'react-three-fiber'
import slides from "../slides.json"
import { lerp } from 'three/src/math/MathUtils'
import data from '../Overlay/data.json';
import { publicURL } from '../constants'
import * as THREE from 'three'
import './3d.css'


export function Part01(props) {
  const group = useRef()
  //const { nodes, materials, animations } = useGLTF('https://corsproxy.io/?url=https://menard.pha.jhu.edu/Biocubes/Part01.glb')
  const { nodes, materials, animations } = useGLTF(publicURL + 'Part01.glb')

  const { actions } = useAnimations(animations, group)
  const imageRef_one = useRef()
  const imageRef_two = useRef()

  const cameraRef = useRef()


  let hiddenClass = (props.counter != props.previousCounter.current) ? 'box_name hidden' : "box_name ";
  const hiddenArtificial = "box_name artificial_name"
  const percentage_of_animation_played = useRef(0)
  let highlighted = slides[props.counter]["highlighted"]
  let divRefs = props.divRefs
  let quantities = slides[props.counter]["quantities"]


  const transp_material = new THREE.MeshStandardMaterial({ color: 0xD3D3D3, opacity: 0.3, transparent: true })
  let animationSpeed = 1
  const invizMaterial = new THREE.MeshStandardMaterial({ opacity: 0, transparent: true })
  const blueMeshMaterial = new THREE.MeshBasicMaterial({ color: 0x287ed4 })
  const whiteSimpleMaterial = new THREE.MeshBasicMaterial({ color: 0xfffffff, toneMapped: false })
  const whiteStandardMaterial = new THREE.MeshStandardMaterial({ color: 0xfffffff, toneMapped: false })
  const blackStandardMaterial = new THREE.MeshStandardMaterial({ color: 0x000000, toneMapped: false })

  //E6E6E6
  const offWhiteStandardMaterial = new THREE.MeshStandardMaterial({ color: 0xE6E6E6, toneMapped: false }) 
  const transparentMaterial = new THREE.MeshPhongMaterial({ color: 0xfffffff, opacity: 0.0, transparent: true })

  const set_views = {
    "tree": [9],
    "boxes": [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,],
    "rising_boxes": [22, 23],
    "end_view": [31, 32, 31, 30]
  }

  const { viewport } = useThree();

  nodes.Animals.geometry.computeVertexNormals()

  const handleAnimalHover = (e) => {
    e.stopPropagation()
    document.body.style.cursor = 'pointer'
    props.setHovered(["Animals"])
    props.setSelectionSet(props.divRefs["Animals"])
  }

  if (cameraRef && cameraRef.current) {

    const newWidth = window.innerWidth;
    const newHeight = window.innerHeight;
    cameraRef.current.aspect = newWidth / newHeight;
    cameraRef.current.updateProjectionMatrix();
  }

  // Linear interpolation (LERP) function
  function lerp(start, end, t) {
    return (1 - t) * start + t * end;
  }


  const concrete_params = [2.1868682129138843, 0.04607402973315096, -1.7510212289049325]
  const aggregates_params =[16.102906182547525, 0.026611052590854785, -5.639723428339764]
  const bricks_params = [0.7977367180011439, 0.03849356686948318, 11.057630794550573]
  const asphalt_params = [3.401280365679252, 0.025256487370533168, -5.211861251509707]
  const metals_params = [1.5950296696156498, 0.026858758090328606, -0.9958300345607234]
  const plastics_params = [0.019426848678435926, 0.052480617412522995, -0.39384483589087593]

  function exponentialLerpParams(params, t) {
    // Ensure t is in the range [0, 1]

    t = Math.min(1, Math.max(0, t));
    return Math.max(params[0] * Math.exp(params[1] * (t * 124)) + params[2], 0)
  }

  // Example list of values
  const values = [2, 15, 9, 5, 8];

  function lerp_values(values, t) {
    // Time parameter for interpolation (0 to 1)
    // Find the indices for the values to interpolate between
    const startIndex = Math.floor(t * (values.length - 1));
    const endIndex = Math.ceil(t * (values.length - 1));

    // Calculate the interpolation factor
    const fraction = (t - startIndex / (values.length - 1)) * (values.length - 1);

    // Perform linear interpolation
    const interpolatedValue = lerp(values[startIndex], values[endIndex], fraction);
    return interpolatedValue
  }



  let change = (viewport.width / 20.0)

  change = window.innerWidth / 5000


  let technicalsOffset = 0;

  let change_value = 7;

  //Chaning the FOV based on zoom

  if(window.innerHeight/window.innerWidth > 0.7) {
    change_value += window.innerHeight/window.innerWidth * 6.0;
  }

  let zoomvalue = 1.0;
  if(window.innerHeight < 500) {
    change_value -= 0.5;
  }

  if(window.innerWidth < 900) {
    change_value += 0.5;
  }



  const handleResize = () => {
  };


  const car_offset = 0.0
  let boxes = Object.keys(data)

  boxes = boxes.filter(function (box_name) {
    return box_name !== 'Animals';
  });

  let animal_boxes = ["Wild Birds", "Cnidarians", "Mollusks", "Marine Arthropods", "Fish", "Nematodes", "Annelids", "Arthropods", "Wild Mammals"]
  let artificialBoxes = ["Cars", "Bricks", "Metals", "Asphalt", "Aggregates", "Concrete", "Plastics"]

  let title_positions = {
    "Annelids": [0, 0, .45],
    "Arthropods": [0, .3, .4],
    "Animals": [-.5, 0, 1.2],
    "Plants": [+1.2, +1, +1.2],
    "LUCA": [0, -1.5, 0.9],
    "Viruses": [0, 0.41, 0.4],
    "Protists": [+.85, 1, .8],
    "Bacteria": [-2, 2.2, 2.3],
    "Fungi": [1.4, 1, 1.2],
    "Archaea": [-1, 1, 1.1],
    "Cnidarians": [0, .3, .32],
    "Fish": [-.6, 0, 0],
    "Humans": [0, 0, .3],
    "Livestock": [0, 0, .35],
    "Marine_Arthropods": [.4, 0, .6],
    "Mollusks": [0, .0, .4],
    "Nematodes": [0, 0, .25],
    "Wild_Birds": [0.0, 0, .2],
    "Wild_Mammals": [0, .2, .2],
    "Concrete": [.5, -.55, .5],
    "Bricks": [.5, -.55, .5],
    "Cars": [.5, -.5, .5],
    "Metals": [.5, -.55, .5],
    "Aggregates": [.5, -.55, .5],
    "Asphalt": [.5, -.55, .5],
    "Plastics": [.5, -.55, .5]
  }


  let weight_positions = {
    "Annelids": [0, 0, -.4],
    "Arthropods": [0.7, 0, .1],
    "Animals": [0, 1.6, 0],
    "Plants": [-1.3, 0, 0],
    "LUCA": [0, 1.5, 0],
    "Viruses": [0, 0, -.4],
    "Protists": [-1, 0, 0],
    "Bacteria": [0, 0, -2.6],
    "Fungi": [-1.3, 0, 0],
    "Archaea": [0, 0, -1.4],
    "Cnidarians": [0, 0, -.4],
    "Fish": [0.7, 0, 0],
    "Humans": [0, 0, -.4],
    "Livestock": [0, 0, -.4],
    "Marine_Arthropods": [0, 0, -.6],
    "Mollusks": [0, 0, -.4],
    "Nematodes": [0, 0, -.4],
    "Wild_Birds": [0, 0, -.2],
    "Wild_Mammals": [0, 0, -.2],
    "Concrete": [0, 0.55, 0],
    "Bricks": [0, 0.55, 0],
    "Cars": [0, 0.6, 0],
    "Metals": [0, 0.6, 0],
    "Aggregates": [0, 0.55, 0],
    "Asphalt": [0, 0.55, 0],
    "Plastics": [0, 0.6, 0]
  }


  const flatten_name = (entry_name) => {
    return entry_name.replace(/ /g, "_")
  }

  const quant_value = useRef(0)

  const artificial_values = useRef({
    "Asphalt": 0,
    "Metals": 0,
    "Concrete": 0,
    "Aggregates": 0,
    "Bricks": 0,
    "Plastics": 0

  })

  const animal_click = (e) => {
    props.setOpenModal(true); 
    props.setHovered(["Animals"]);

    e.stopPropagation();
  }

  const rise_percentage = (value) => {
    let start_time = slides[22]["animationTime"] + car_offset
    let end_time = slides[23]["animationTime"]
    let length = end_time - start_time
    let cur_value = value - start_time
    let perc = cur_value / length
    return perc
  }

  const flatYear = (value) => {
    let perc = rise_percentage(value)
    return perc
  }


  const clickBox = (e, box_name) => {
    props.setOpenModal(true);
    props.setHovered([box_name]);
    e.stopPropagation();
  }

  //https://elsenaju.eu/Calculator/online-curve-fit.htm
  const e_square_function = (a, b, c, x) => {
    return a * Math.pow(Math.E, b * Math.pow((x + c), 2))
  }
  useEffect(() => {
    playAnimations(actions)

    props.setLoaded3D(true);
    setAnimationTime(actions, slides[props.counter]["animationTime"])

  }, [])

  useFrame((state, delta) => {
    if (props.previousCounter.current != props.counter) {
      let anim_length = Math.abs(slides[props.counter]["animationTime"] - slides[props.previousCounter.current]["animationTime"])
      percentage_of_animation_played.current += animationSpeed / anim_length * delta

      if (props.scrubbing || Math.abs(props.previousCounter.current - props.counter) > 1) {
        props.animationTime.current = slides[props.counter]["animationTime"]
        props.previousCounter.current = props.counter
        props.setcounterHit(true)

      } else {
        props.animationTime.current = lerp(slides[props.previousCounter.current]["animationTime"], slides[props.counter]["animationTime"], Math.min(percentage_of_animation_played.current, 1))
      }

      setAnimationTime(actions, props.animationTime.current)

      if (percentage_of_animation_played.current >= 1.0) {
        props.previousCounter.current = props.counter
        percentage_of_animation_played.current = 0
        hiddenClass = "box_name hidden"
        props.setcounterHit(true)
      }
    }

    if (imageRef_one.current) {
      imageRef_one.current.material.opacity = (Math.abs(Math.sin(state.clock.getElapsedTime() * 3))) * 0.4 + 0.6;

    }
    if (imageRef_two.current) {
      imageRef_two.current.material.opacity = (Math.abs(Math.sin(state.clock.getElapsedTime() * 3))) * 0.4 + 0.6;

    }

    if (set_views["rising_boxes"].includes(props.counter)) {
      props.setYearPercentage(flatYear(props.animationTime.current))
      quant_value.current = rise_percentage(props.animationTime.current)

      artificial_values.current["Concrete"] = exponentialLerpParams(concrete_params, rise_percentage(props.animationTime.current))
      artificial_values.current["Aggregates"] = exponentialLerpParams(aggregates_params, rise_percentage(props.animationTime.current))
      artificial_values.current["Bricks"] = exponentialLerpParams(bricks_params, rise_percentage(props.animationTime.current))
      artificial_values.current["Metals"] = exponentialLerpParams(metals_params, rise_percentage(props.animationTime.current))
      artificial_values.current["Asphalt"] = exponentialLerpParams(asphalt_params, rise_percentage(props.animationTime.current))
      artificial_values.current["Plastics"] = exponentialLerpParams(plastics_params, rise_percentage(props.animationTime.current))

    }
  })


  if (set_views["tree"].includes(props.counter)) {
    title_positions["Fungi"] = [1.2, 1, -0.5]
    title_positions["Archaea"] = [-1, 0, 1.2]
    title_positions["Bacteria"] = [-2, 0, 2.5]



  }
  if (set_views["boxes"].includes(props.counter)) {
    title_positions["Plants"] = [-1.25, +1, +1.2]
    title_positions["Protists"] = [-1.25, +1, 0]
    title_positions["Animals"] = [1.85, -0.6, -1]

  }

  if (set_views["rising_boxes"].includes(props.counter)) {
    title_positions["Metals"] = [-0.4, 0.7, 0]
    title_positions["Asphalt"] = [-0.4, 0.7, 0]
    title_positions["Bricks"] = [-0.4, 0.7, 0]
    title_positions["Aggregates"] = [-0.4, 0.7, 0]
    title_positions["Concrete"] = [-0.3, 0.7, 0]
    title_positions["Plastics"] = [-0.4, 0.7, 0]

  }

  if (set_views["end_view"].includes(props.counter)) {
    title_positions["Plastics"] = [0.4, 0.9, 0]
    title_positions["Metals"] = [0.4, 0.8, 0]
    title_positions["Asphalt"] = [0.6, 0.8, 0]
    title_positions["Bricks"] = [0.8, 0.8, 0]
    title_positions["Plants"] = [-1.35, +1, +1.2]
    title_positions["Protists"] = [-1.5, 0, -.2]
    title_positions["Fungi"] = [1.2, 0, 1.2]
    title_positions["Aggregates"] = [.5, -.50, .5]
    title_positions["Concrete"] = [.5, -.50, .5]
  }

  let glow_icon_mat = materials.Icons
  glow_icon_mat.emissive = glow_icon_mat.color
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <PerspectiveCamera name="Camera" makeDefault
          far={1000} near={0.1}
          position={[0.759, 19.945, 20.304]} rotation={[-0.2, 0, 0]} scale={5.334}
          ref={cameraRef}
          fov={change_value}

        />

        {boxes.map((name, i) => {
          let flat_name = flatten_name(name)
          let currentClass = hiddenClass
          let icon_mat = materials.Icons
          let is_artificial = artificialBoxes.includes(name)
          let is_animal = animal_boxes.includes(name)
          let weight_box = "box_value"
          if (is_animal) {
            icon_mat = glow_icon_mat
          }

          if (is_artificial) {
            currentClass = hiddenArtificial
          }

          let trans_amount = "0%"
          let luca_box = true;
          if (is_artificial) {
            trans_amount = "-120%"
          }


          let human_title = name.toLowerCase()
          if (name == "Humans" && props.counter >= 20 && props.previousCounter.current > 19) {
            human_title = "all humans"
          }

          if (name == "Humans" && props.counter >= 20  && props.counter < 24) {
            human_title = ""
          }
          let weight_val = data[name].weight

          if (Object.keys(artificial_values.current).includes(name) &&
            set_views["rising_boxes"].includes(props.counter)) {
            weight_val = Math.round(artificial_values.current[name])
          }

          if (props.hovered.includes(name)) {
            currentClass += " hovered_name"
          }

          let currently_highlighted = false
          if (highlighted.includes(name)) {
            currentClass += " highlighted_name"
            currently_highlighted = true
          }
          if (name == "Arthropods") {
            weight_box = "box_value left"
          }

          if(flat_name == "LUCA") {
            human_title = "LUCA";
            if(props.counter < 9) {
              luca_box = false;
            }

          } 

          return (

            <>
            <mesh name={flat_name} geometry={nodes[flat_name].geometry} key={i}
              material={highlighted.includes(name) ? transp_material : nodes[flat_name].material}

              morphTargetDictionary={false ? null : nodes[flat_name].morphTargetDictionary}
              morphTargetInfluences={false ? null : nodes[flat_name].morphTargetInfluences}
              
              position={nodes[flat_name].position}
              rotation={nodes[flat_name].rotation}
              frustumCulled={false}
              onClick={(e) => {clickBox(e, flat_name)}}
              castShadow={!is_animal && !currently_highlighted}
              receiveShadow

              onPointerMove={
                (([props.counter, props.previousCounter.current].sort().toString() == [22, 23].toString()) || props.counter == 22)
                  ? null : props.handleHover} onPointerOut={props.handleUnhover}
              ref={ref => divRefs.current[name] = ref}
            >
              <mesh name={flat_name + "_Icon"} geometry={nodes[flat_name + "_Icon"].geometry} material={icon_mat}
                position={nodes[flat_name + "_Icon"].position} rotation={nodes[flat_name + "_Icon"].rotation} scale={nodes[flat_name + "_Icon"].scale} />

              {(nodes[flat_name + "_Text"]) && (
                <mesh name={flat_name + "_Text"} geometry={nodes[flat_name + "_Text"].geometry} material={icon_mat}
                  position={nodes[flat_name + "_Text"].position} rotation={nodes[flat_name + "_Text"].rotation} scale={nodes[flat_name + "_Text"].scale} />
              )}

              <group>

              {props.counter != 22 && (
                
              <Html
                style={{
                  transition: 'all 0.2s',
                  transform: 'translate(-50%, -100%)'
                }}
                distanceFactor={10}
                zIndexRange={[100, 100]}
                position={weight_positions[flat_name]}
                className={(props.hovered.includes(flat_name) || quantities.includes(flat_name)) ? weight_box : weight_box + " hide_box"}
              >
                <h1>{weight_val} Gt </h1>
              </Html>
              )}
              </group>

              <group>
                {(!nodes[flat_name + "_Text"]) && (((!is_artificial || props.counter > 23) || (flat_name == "Cars"))) && (props.counter>5) && (luca_box) && (
                <>
                <Html position={title_positions[flat_name]}
                  scale={0.4 / nodes[flat_name].scale.x}
                  style={{"pointerEvents": "none"}}
                  center={true}
                  distanceFactor={10}
                  zIndexRange={[0, 20]}
                  className={currentClass}>
                  <h1>{human_title}</h1>
                </Html>
                </>

              )}

              </group>




            </mesh>


            

            </>

          )
        })}


        {
        ((props.counter <= 23 && props.counter > 21)) && (
        <>
        {true && (
          <Html
            style={{
              transform: 'translate(-50%, ' + technicalsOffset + '%)'
            }}
            distanceFactor={10}

            position={[3, 1, 13.8094]}
            className={(props.animationTime.current > 26.5) ? "box_name extra_technicals": "box_name extra_technicals hidden"}
          >
            <h1>
              plastics
            </h1>
          </Html>
        )}


        {true && (
          <Html
            style={{
              transform: 'translate(50%, '+ technicalsOffset +'%)'
            }}
            distanceFactor={10}

            position={[3.98992, 1, 12.0868]}
            className={(props.animationTime.current > 27)? "box_name extra_technicals": "box_name extra_technicals hidden"}
          >
            <h1>
              metals
            </h1>
          </Html>
        )}

        { true && (
          <Html
            style={{
              transform: 'translate(20%, ' + technicalsOffset + '%)'
            }}
            distanceFactor={10}

            position={[6.2824, 1, 7.81034]}
            className={(props.animationTime.current > 27.3)? "box_name extra_technicals ": "box_name extra_technicals hidden"}
          >
            <h1>
              asphalt
            </h1>
          </Html>
        )}


        {true && (
          <Html
            style={{
              transform: 'translate(50%, ' + technicalsOffset + '%)'
            }}
            distanceFactor={10}

            position={[8.62164, 1, 4.52896]}
            className={(props.animationTime.current > 28.0) ? "box_name extra_technicals": "box_name extra_technicals hidden"}
          >
            <h1>
              bricks
            </h1>
          </Html>
        )}


        {true && (
          <Html
            style={{
              transform: 'translate(50%, ' + technicalsOffset + '%)'
            }}
            distanceFactor={10}

            position={[11.2608, 1, -0.11226]}
            className={(props.animationTime.current > 28.2)? "box_name  extra_technicals": "box_name extra_technicals hidden"}
          >
            <h1>
              aggregates
            </h1>
          </Html>
        )}


        {true && (
          <Html
            style={{
              transform: 'translate(100%, ' + technicalsOffset + '%)'
            }}
            distanceFactor={10}

            position={[15.391, 1, -7.2317]}
            className={(props.animationTime.current > 29.2)? "box_name extra_technicals ": "box_name extra_technicals hidden"}
          >
            <h1>
              concrete
            </h1>
          </Html>
        )}


        {true && (
          <Html
            style={{
              transform: 'translate(-50%, ' + technicalsOffset +  '%)'
            }}
            distanceFactor={10}

            position={[8.25977, 1.0, 17]}
            className={(props.animationTime.current > 29.8) ? "box_name extra_technicals ": "box_name extra_technicals hidden"}
          >
            <h1>
              all humans
            </h1>
          </Html>
        )}
        </>

        )}

        <group name="2023" position={[-1.568, 0.606, 5.289]} scale={0.177}>
          <group name="Word" position={[-1.559, -0.045, 0.839]} scale={2.413} >
          </group>
          <mesh name="2023_Text" geometry={nodes['2023_Text'].geometry} material={materials.Icons} position={[-1.011, -0.518, 0.679]} rotation={[Math.PI / 2, 0, -Math.PI / 6]} scale={0.832} />
          <mesh name="Plane002" geometry={nodes.Plane002.geometry} material={whiteSimpleMaterial} position={[2.284, 0.005, -1.01]} rotation={[Math.PI / 2, 0, 2.653]} scale={[-1.829, -1.937, -0.047]} />
        </group>


        <group name="1900" position={[-1.568, 0.956, 5.289]} scale={0.177}>
          <group name="Word002" position={[-1.559, -0.045, 0.839]} scale={2.413} >
          </group>
          <mesh name="1900_Text" geometry={nodes['1900_Text'].geometry} material={materials.Icons} position={[-1.011, 0.32, 0.679]} rotation={[Math.PI / 2, 0, -Math.PI / 6]} scale={0.832} />
          <mesh name="Plane004" geometry={nodes.Plane004.geometry} material={whiteSimpleMaterial} position={[2.284, 0.005, -1.01]} rotation={[Math.PI / 2, 0, 2.653]} scale={[-1.829, -1.937, -0.047]} />
        </group>
        <group name="1990" position={[-1.568, 0.895, 5.289]} scale={0.177}>
          <group name="Word001" position={[-1.559, -0.045, 0.839]} scale={2.413} >
          </group>
          <mesh name="1990_Text" geometry={nodes['1990_Text'].geometry} material={materials.Icons} position={[-1.011, 0.369, 0.679]} rotation={[Math.PI / 2, 0, -Math.PI / 6]} scale={0.832} />
          <mesh name="Plane003" geometry={nodes.Plane003.geometry} material={whiteSimpleMaterial} position={[2.284, 0.005, -1.01]} rotation={[Math.PI / 2, 0, 2.653]} scale={[-1.901, -2.014, -0.049]} />
        </group>



        <group name="Empty" position={[0, 1.148, 0]} scale={1.086}>
          <group name="Armature" position={[0, -1.057, 0]} scale={0.921}>
            <primitive object={nodes.Bone} />
            <primitive object={nodes.Bone001} />
            <primitive object={nodes.neutral_bone} />
            <Box position={[0, 0.7, 0]} scale={1.4}
              onPointerOver={handleAnimalHover}
              onPointerOut={props.handleUnhover}
              onClick={animal_click}
              material={invizMaterial}

            />
            <Html
              style={{
                transition: 'all 0.2s',
                transform: 'translate(-50%, -100%)'
              }}
              distanceFactor={10}
              zIndexRange={[100, 100]}
              position={!(props.counter < 30 && props.counter > 9) ? weight_positions["Animals"] : [-1, 0.5, -0.5]}
              className={(props.hovered.includes("Animals")) ? "box_value" : "box_value hide_box"}
            >
              <h1>{data["Animals"].weight} Gt </h1>
            </Html>
            <skinnedMesh name="Animals" geometry={nodes.Animals.geometry}
              material={highlighted.includes("Animals") ? transp_material : materials.Animals}
              skeleton={nodes.Animals.skeleton}
              ref={ref => divRefs.current["Animals"] = ref}
              frustumCulled={false}
              castShadow={!highlighted.includes("Animals")}
            >
            </skinnedMesh>
          </group>
          <mesh name="Animals_Text" geometry={nodes.Animals_Text.geometry} material={materials.Icons} position={[0.525, -0.6717, 0.709]} rotation={[Math.PI / 2, 0, 0]} scale={0.914} />
        </group>


        <mesh name="Plane001" geometry={nodes.Plane001.geometry} material={materials.Tree} position={[1.247, -4.049, 1.436]} rotation={[Math.PI / 2, 0, 0]} scale={[6.678, 3.04, 3.047]} />
        <mesh name="Plane" geometry={nodes.Plane.geometry} material={materials.Rollout} position={[0.976, -2.488, 9.278]} rotation={[Math.PI / 2, 0, 0]} scale={[0.009, 0.723, 0.075]} />
        <mesh name="Plane006" geometry={nodes.Plane006.geometry} material={materials['Material.007']} position={[2.952, 1.018, 13.31]} rotation={[0, 0.494, 0]} scale={[0.075, 0.4, 0.075]} />
        <mesh name="Plane007" geometry={nodes.Plane007.geometry} material={materials['Material.007']} position={[3.941, 1.018, 10.675]} rotation={[0, 0.494, 0]} scale={[0.075, 0.643, 0.075]} />
        <mesh name="Plane008" geometry={nodes.Plane008.geometry} material={materials['Material.007']} position={[5.795, 1.018, 6.829]} rotation={[0, 0.494, 0]} scale={[0.075, 0.759, 0.075]} />
        <mesh name="Plane009" geometry={nodes.Plane009.geometry} material={materials['Material.007']} position={[8.31, 1.018, 2.542]} rotation={[0, 0.494, 0]} scale={[0.075, 0.884, 0.075]} />
        <mesh name="Plane010" geometry={nodes.Plane010.geometry} material={materials['Material.007']} position={[10.602, 1.018, -3.055]} rotation={[0, 0.494, 0]} scale={[0.075, 1.389, 0.075]} />
        <mesh name="Plane011" geometry={nodes.Plane011.geometry} material={materials['Material.007']} position={[14.742, 1.018, -10.155]} rotation={[0, 0.494, 0]} scale={[0.075, 1.589, 0.075]} />

        <mesh receiveShadow name="Plane005" geometry={nodes.Plane005.geometry} material={offWhiteStandardMaterial} position={[3.417, 1.011, -2.809]} scale={2564.796} />
        <mesh name="Biomass_Text" geometry={nodes.Biomass_Text.geometry} material={materials.Icons} position={[4.018, 6.423, -3.633]} rotation={[-Math.PI / 2, 0, 2.934]} scale={3.6} />
        <mesh name="Technomass_Text" geometry={nodes.Technomass_Text.geometry} material={materials.Icons} position={[9.627, 7.454, -3.633]} rotation={[-Math.PI / 2, 0, -3.012]} scale={3.6} />
        {artificialBoxes.map((name, i) => {
          if (name == "Cars") {
            return <></>
          } else if (props.counter == 22) {
            let mesh_name = name + "_Hover"
            return <mesh name={mesh_name}
              ref={ref => divRefs.current[mesh_name] = ref}
              onPointerOver={props.handleHover}
              onPointerOut={props.handleUnhover}
              geometry={nodes[mesh_name].geometry}
              material={transparentMaterial}
              position={nodes[mesh_name].position}
              rotation={nodes[mesh_name].rotation}
              scale={nodes[mesh_name].scale} />
          }
        })}


      </group>
    </group>
  )
}

